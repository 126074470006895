import { Controller } from 'stimulus';
export default {
  'symfony--ux-chartjs--chart': new Promise((resolve, reject) => resolve({ default:
      (function() {
          function LazyController(context) {
              this.__stimulusLazyController = true;
              Controller.call(this, context);
          }
          LazyController.prototype = Object.create(Controller && Controller.prototype, {
              constructor: { value: LazyController, writable: true, configurable: true }
          });
          Object.setPrototypeOf(LazyController, Controller);
          LazyController.prototype.initialize = function() {
              var _this = this;
              if (this.application.controllers.find(function(controller) {
                  return controller.identifier === _this.identifier && controller.__stimulusLazyController;
              })) {
                  return;
              }
              import('@symfony/ux-chartjs/dist/controller.js').then(function(controller) {
                  _this.application.register(_this.identifier, controller.default);
              });
          }
          return LazyController;
      })()
  })),
};